@import '/src/styles/colors';

#upload-finished-modal {
    z-index: 999;
    position: absolute;
    top: 5px;
    left: -350px;
    background-color: $primary;
    padding: 15px 19px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 0.6s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    &.show {
        left: 0px;
        transition: 0.6s;
    }

    span {
        color: $white;
    }
}
