@import '/src/styles/colors';
@import '/src/styles/mixins';

.templates-sidebar {
    z-index: 8;
    position: fixed;
    bottom: 40px;
    left: 0px;
    height: 0px;
    background-color: $grey1;
    width: 100%;
    display: none;
    padding: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &.show-sidebar {
        height: 270px;
        width: 100%;
        display: initial;
    }

    .container-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .container-select {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @include md {
        position: fixed;
        bottom: 0px;
        left: 50px;
        height: calc(100% - 110px);
        background-color: $grey1;
        width: 0px;
        display: none;
        padding: 10px;

        &.show-sidebar {
            height: calc(100% - 110px);
            width: 225px;
            display: initial;
        }

        .container-selector {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container-select {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
