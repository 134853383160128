@import '/src/styles/colors';
@import '/src/styles/mixins';

.header-sidebar {
    z-index: 1;
    padding: 15px;
    top: 0px;
    position: sticky;
    background-color: $grey1;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);

    .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            font-size: 1.2rem;
        }
    }

    .size-title {
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: 700;
    }

    .sizes-container {
        display: grid;
        grid-template-columns: 1fr 1fr;

        p span {
            font-weight: 600;
        }
    }

    p {
        margin: 0;
    }
}
