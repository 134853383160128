@import '/src/styles/colors';
@import '/src/styles/mixins';

.container-wrapper-templates {
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;

    .container-templates {
        min-width: max-content;
        width: max-content;
        margin-right: 20px;

        .title {
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 5px;
            text-align: center;
        }

        .group-templates {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .template.library {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0px;
                margin-right: 10px;

                img {
                    max-width: 100px;
                    max-height: 100px;
                }

                &.selected {
                    img {
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        border: 3px solid $secondary;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
@include md {
    .container-wrapper-templates {
        margin-top: 10px;
        width: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        height: inherit;
        flex-wrap: wrap;
        justify-content: center;

        .container-templates {
            min-width: initial;
            width: initial;
            margin-right: 0px;

            &:last-child {
                margin-bottom: 20px;
            }

            .group-templates {
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                .title {
                    font-size: 16px;
                    text-align: center;
                    margin-top: 0px;
                    margin-bottom: 5px;
                }

                .template.library {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
                    margin-right: 0px;

                    img {
                        max-width: 200px;
                        max-height: 200px;
                    }

                    &.selected {
                        img {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            border: 3px solid $secondary;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
