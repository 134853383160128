.label-template {
    display: block;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 500;
}

.container-presets {
    overflow-y: auto;
    overflow-x: hidden;
    height: 67vh;
}

.container-templates.presets-server {
    min-width: 80vw;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
    overflow-y: auto;

    .template.library {
        transition: 0.2s;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 150px;
            max-height: 150px;
            object-fit: contain;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: 0.2s;
        }
    }
}

.container-button-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
        width: 100%;
    }
}

.container-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
