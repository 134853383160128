@import './styles/fonts';

* {
    @include openSans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    @include openSans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
