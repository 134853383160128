$white: #ffffff;
$primary: #591fe2;
$secondary: #f05941;
$grey: #d0cfcf;

$primary-dark: darken($primary, 10%);
$secondary-dark: darken($secondary, 10%);
$grey-dark: darken($grey, 10%);

$primary-light: #8e8ffa;

$grey1: #efefef;
$grey2: #cccccc;
$grey3: #303030;
