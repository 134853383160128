@import '/src/styles/colors';

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        background-color: $white;
        border: none;
        box-shadow: none;
        outline: 1.5px solid $primary;
        transition: 0.1s;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            position: absolute;
            display: block;
            left: 11px;
            top: 6px;
            width: 9px;
            height: 14px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:checked {
            background-color: $primary;
            outline: 1.5px solid $primary;
            transition: 0.1s;
            background-image: url('../../../public/assets/img/check.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    label {
        user-select: none;

        &:hover {
            cursor: pointer;
        }
    }
}
