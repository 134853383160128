@import '../../styles/fonts';

.loader-editor {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(155, 155, 155, 0.4);
    z-index: 97;

    .loader-text {
        max-width: 300px;
        text-align: center;
        @include openSansSemiBold;
    }
}
