@import '/src/styles/mixins';

.container-session {
    .container-checkbox {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        flex-direction: column;

        @include md {
            flex-direction: row;
            justify-items: center;
        }
    }

    .container-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        margin-top: 30px;
        gap: 10px;
    }
}
