@import '/src/styles/colors';
@import '/src/styles/mixins';

.carousel-stickers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: hidden;
}
