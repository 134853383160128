@import '/src/styles/colors';

.input-field {
    margin-bottom: 15px;

    .label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        font-size: 1.2rem;
        padding: 3px 10px;
        border-radius: 5px;
        border: 2px solid $primary-light;

        &:focus {
            outline: none;
            border: 2px solid $primary;
        }
    }
}
