@import '/src/styles/colors';
@import '/src/styles/mixins';

.stickers-sidebar {
    z-index: 98;
    position: fixed;
    bottom: 0;
    right: 0;
    height: calc(100vh - 60px);
    background-color: $grey1;

    .container-sidebar {
        width: 0px;
        transition: width 0.3s ease;
        overflow-y: auto;
        height: 100%;

        &.show-sidebar {
            width: 400px;
            max-width: calc(100vw - 30px);
        }
    }
}
