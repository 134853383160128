@import '/src/styles/mixins';

.container-app {
    height: 100vh;
    display: grid;
    grid-template-rows: 60px 1fr;
}

.hide-custom-templates {
    [aria-label='CUSTOM TEMPLATES'] {
        display: none;
    }
}

[aria-label='AI TEMPLATES'] {
    display: none;
}

.photo-editor {
    position: relative;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height: 100%;

    img[alt='watermark'] {
        display: none;
    }
}
