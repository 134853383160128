@import '/src/styles/colors';
@import '/src/styles/mixins';

.stickers-sidebar {
    .button-show-hide {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        position: absolute;
        width: 200px;
        transform: rotate(90deg);
        left: -115px;
        top: calc((100vh - 200px) / 2);
        margin-left: auto;
        margin-right: auto;
        color: $grey3;
        font-size: 0.9rem;

        @include md {
            top: calc((100vh - 60px) / 2);
        }

        .inner-button-show-hide {
            width: fit-content;
            display: flex;
            justify-content: center;
            padding: 10px 15px 5px;
            align-items: center;
            flex-direction: row;
            background-color: $grey1;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }

        .container-chevron {
            padding-top: 2px;
            padding-left: 7px;

            .chevron {
                transform: rotate(-90deg);
                width: 12px;

                &.open {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
