@import '/src/styles/mixins';

.fourK {
    position: absolute;
    top: 70px;
    right: 10px;
    z-index: 1;
    width: 40px;

    @include md {
        top: 80px;
        right: 20px;
        width: 50px;
    }
}
