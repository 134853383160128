@import '/src/styles/colors';

.file-upload {
    .hidden {
        display: none;
    }

    .file-drop {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .file-drop-target {
            padding: 20px;
            width: 100%;
            height: 100%;
            min-height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            border: 2px solid $primary-light;

            &.file-drop-dragging-over-target {
                border: 2px solid $primary;
            }

            .icon-upload {
                width: 40px;
                margin-bottom: 10px;
            }

            p {
                margin: 0px;

                &.files-selected {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-weight: 350;

                    span {
                        display: block;
                    }
                }

                &.files-allowed {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-style: italic;
                    font-weight: 350;
                }
            }

            .error-text-upload {
                margin-top: 10px;
                font-size: 14px;
                color: $secondary;
            }
        }
    }
}
