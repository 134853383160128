.container-upload-stickers {
    .container-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
    }

    .container-button {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}