@import '/src/styles/colors';
@import '/src/styles/mixins';

.element-text {
    .font-selector-container {
        padding: 0px 10px;
        margin-bottom: 10px;
    }

    .controls {
        grid-template-columns: 1fr;

        .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .button-move {
                width: 100%;
            }
        }
    }
}
