@import '/src/styles/colors';
@import '/src/styles/mixins';

.custom-button {
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 49px;
    max-height: 49px;

    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.2;
    color: $white;
    background: $primary;
    border: 1.5px solid $primary;
    border-radius: 4px;
    padding: 5px 10px;
    transition: 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1300px) {
        height: 100%;
    }

    &:hover {
        background: $primary-dark;
        border: 1.5px solid $primary-dark;
        transition: 0.2s;
    }

    &.grey {
        color: $grey3;
        background: $grey;
        border: 1.5px solid $grey;

        &:hover {
            background: $grey-dark;
            border: 1.5px solid $grey-dark;
            transition: 0.2s;
        }
    }

    &.secondary {
        color: $white;
        background: $secondary;
        border: 1.5px solid $secondary;

        &:hover {
            background: $secondary-dark;
            border: 1.5px solid $secondary-dark;
            transition: 0.2s;
        }
    }

    &.disabled {
        color: $white;
        background: $grey1;
        border: 1.5px solid $grey1;

        &:hover {
            cursor: inherit;
        }
    }

    .spinner {
        margin-right: 10px;
        border: 2px solid $grey2;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border-left-color: $white;

        animation: spin 1s ease infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
