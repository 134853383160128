.container-error {
    .text-error {
        position: relative;

        .icon-error {
            margin-right: 7px;
            transform: translateY(5px);
        }
    }
    

    .container-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
    }
}