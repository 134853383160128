/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./../../public/assets/fonts/open-sans-v29-latin-regular.woff2') format('woff2'),
        url('./../../public/assets/fonts/open-sans-v29-latin-regular.woff') format('woff'),
        url('./../../public/assets/fonts/open-sans-v29-latin-regular.ttf') format('truetype');
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./../../public/assets/fonts/open-sans-v29-latin-600.woff2') format('woff2'),
        url('./../../public/assets/fonts/open-sans-v29-latin-600.woff') format('woff'),
        url('./../../public/assets/fonts/open-sans-v29-latin-600.ttf') format('truetype');
}

/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./../../public/assets/fonts/open-sans-v29-latin-800.woff2') format('woff2'),
        url('./../../public/assets/fonts/open-sans-v29-latin-800.woff') format('woff'),
        url('./../../public/assets/fonts/open-sans-v29-latin-800.ttf') format('truetype');
}

@mixin openSans {
    font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
    font-weight: 400;
}

@mixin openSansSemiBold {
    font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
    font-weight: 600;
}

@mixin openSansBold {
    font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
    font-weight: 800;
}
