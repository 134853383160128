@import '/src/styles/colors';

.container-loader-stickers {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.container-message {
    margin-top: 10px;

    h2 {
        font-size: 16px;
    }
}

.container-design-library {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 5px;
    align-items: stretch;
    overflow-y: auto;
    max-height: 67vh;
    padding-right: 15px;

    .template {
        position: relative;
        max-width: 200px;
        transition: 0.2s;
        margin: 5px;

        img {
            max-width: 100%;
            object-fit: contain;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: 0.2s;
        }

        .action {
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 35px;
            background-color: $grey2;
            border-radius: 50%;
        }
    }
}
