@import '/src/styles/colors';
@import '/src/styles/mixins';

.top-bar {
    background-color: $grey1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 60px;
    z-index: 99;
    flex-direction: row-reverse;

    @include md {
        flex-direction: row;
    }

    .container-logo img {
        height: 25px;
        margin-left: 0px;

        @include md {
            margin-left: 20px;
        }
    }

    .hamburger-menu {
        z-index: 10;
        position: relative;
        display: block;

        @include md {
            display: none;
        }

        .hamburger {
            z-index: 5;

            &:hover {
                cursor: pointer;
            }

            &.open {
                .top-bun {
                    transform-origin: top right;
                    transform: rotate(-42deg);
                }
                .meat {
                    opacity: 0;
                }
                .bottom-bun {
                    transform-origin: bottom right;
                    transform: rotate(42deg);
                }
                .nav {
                    top: 0;
                    transform: scale(1);
                }
            }
        }

        .hamburger div {
            position: relative;
            width: 25px;
            height: 3px;
            border-radius: 3px;
            background-color: $grey3;
            margin-top: 5px;
            transition: all 0.3s ease-in-out;

            &.top-bun {
                margin-top: 0px;
            }
        }
    }

    .container-buttons-mobile {
        z-index: 9;
        position: absolute;
        top: -100vh;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $grey1;
        transition: 0.2s;

        @include md {
            display: none;
        }

        &.open {
            top: 0;
            transition: 0.2s;
        }

        .group {
            width: 90%;
            margin-bottom: 10px;

            .custom-button {
                display: block;
                width: 100%;
                margin: 5px 0px;
            }

            .separator p {
                margin: 0px;
                margin-top: 10px;
                font-weight: 600;
                font-size: 18px;
                text-align: center;
            }
        }
    }

    .container-buttons-desktop {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 10px;

        @include md {
            display: flex;
        }
    }

    .container-submenu {
        position: relative;
        display: block;

        .submenu {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            max-height: 0px;
            width: max-content;
            border-radius: 5px;
            overflow: hidden;
            transition: 0.2s;
            background-color: $grey1;
            left: -10px;

            &.open {
                max-height: 400px;
                transition: 0.2s ease;
            }

            button {
                width: -moz-available;
                margin: 10px 10px 0px 10px;
                height: 100%;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .top-bar {
        padding: 5px 15px;
    }
}

@media (max-width: 1072px) {
    .top-bar .container-buttons > .custom-button:last-child {
        min-height: 44px;
    }
}
