@import '/src/styles/colors';

.container-templates.container-stickers {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

    .template.sticker {
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
            background-color: $white;
        }
    }
}
