@import '/src/styles/colors';
@import '/src/styles/mixins';

.element-carousel {
    width: 100%;
    border-radius: 15px;
    border: 1px solid $grey;
    margin-bottom: 10px;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);

    .image-container {
        position: relative;
        background-color: $white;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: hidden;
        margin-bottom: 10px;

        .trash {
            width: 20px;
            position: absolute;
            top: 15px;
            right: 15px;
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }

        .stiker {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .controls {
        display: grid;
        grid-template-columns: 1fr;

        @include sm {
            grid-template-columns: 2fr 1fr;
        }

        .size-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .chain-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
                padding: 2px 5px;
                border-radius: 4px;

                &:hover {
                    background-color: $grey2;
                    cursor: pointer;
                }

                .chain {
                    position: relative;
                    top: 1px;
                    width: 20px;
                }
            }
        }

        .buttons-container {
            display: grid;
            grid-template-rows: 1fr 1fr;
            gap: 10px;
            padding: 0px 10px;
            margin-bottom: 10px;

            .button-move {
                background-color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 5px;
                padding-bottom: 10px;
                border-radius: 5px;
                border: 1px solid $grey;
                -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
                transition: 0.2s;

                &:hover {
                    background-color: $grey;
                    cursor: pointer;
                }

                &.disabled {
                    cursor: initial;
                    background-color: $grey2;
                }

                .arrow {
                    width: 20px;
                    transform: rotate(90deg);

                    &.down {
                        transform: rotate(-90deg);
                    }
                }

                .text {
                    margin-top: 2px;
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    text-align: center;
                }
            }
        }

        .positions-container {
            padding: 0px 10px;
            margin-bottom: 10px;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            .text-explanation {
                font-size: 0.86rem;
                font-weight: 600;
            }

            .container-col {
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;

                .input-selection {
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 20px;
                    margin-right: 10px;

                    .input-text {
                        font-size: 0.85rem;
                    }

                    .input-container {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex-direction: row;

                        input {
                            margin: 0px 5px;
                            width: 50px;
                            text-align: center;
                            height: 20px;
                            border: 0px;
                            border-bottom: 1px solid $primary;
                            background-color: $grey1;
                        }
                    }
                }
            }
        }
    }
}
